import { FC, Fragment, PropsWithChildren, useCallback } from 'react';

import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import ConversionNumber from 'bloko/blocks/conversion';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import Notification from 'src/components/Notifications/Notification';
import { NotificationProps } from 'src/components/Notifications/Provider/types';

export enum ToggleVacancyOptionErrorKey {
    FailedToDeleteExpired = 'FAILED_TO_DELETE_EXPIRED',
    PremiumAlreadySet = 'PREMIUM_ALREDY_SET',
    PremiumNotSet = 'PREMIUM_NOT_SET',
    FailedToDelete = 'FAILED_TO_DELETE',
    NotZpVacancy = 'NOT_ZP_VACANCY',
    FailedToSpendService = 'FAILED_TO_SPEND_SERVICE',
    FailedToReturnService = 'FAILED_TO_RETURN_SERVICE',
    FailedToGetServiceCount = 'FAILED_TO_GET_SERVICE_COUNT',
    NotEnoughQuotas = 'NOT_ENOUGH_QUOTAS',
    VacancyIsNotActive = 'VACANCY_IS_NOT_ACTIVE',
    NotEnoughServices = 'NOT_ENOUGH_SERVICES',
}

export enum VacancyToggleAction {
    Enable = 'enable',
    Disable = 'disable',
}

const TrlKeys = {
    ArchiveVacancySuccess: {
        single: 'employer.vacancy.archive.result',
        anyBegin: 'employer.vacancies.archive.result.new',
        someEnd: 'vacancy.some',
        manyEnd: 'vacancy.many',
        descriptionOne: 'employer.vacancy.archive.result.description.one',
        descriptionMany: 'employer.vacancy.archive.result.description.many',
    },
    VacancyUpdateSuccess: {
        successOne: 'employer.myVacancies.successOne',
        successSome: 'employer.myVacancies.successSome',
    },
    VacancyUpdateError: {
        errorOne: 'vacancy.rise.error.nonProlongableVacancies.one',
        errorSome: 'vacancy.rise.error.nonProlongableVacancies.many',
    },
    AutoProlongationPurchaseSuccess: {
        message: 'employer.vacancies.autoprolongation.publicationPurchase.success',
    },
    AutoProlongationPurchaseSuccesses: {
        message: 'employer.vacancies.autoprolongation.publicationPurchase.batch.success',
    },
    VacancyAutoProlongationSuccess: {
        [VacancyToggleAction.Disable]: {
            title: 'employer.vacancies.autoProlongation.disable.ok.title',
            content: 'employer.vacancies.autoProlongation.disable.ok.content',
        },
        [VacancyToggleAction.Enable]: {
            title: 'employer.vacancies.autoProlongation.enable.ok.title',
            content: 'employer.vacancies.autoProlongation.enable.ok.content',
        },
    },
    VacancyAutoProlongationSuccesses: {
        title: 'employer.vacancies.autoProlongation.batch.enable.ok.title',
        content: 'employer.vacancies.autoProlongation.batch.enable.ok.content',
    },
    VacancyAutoProlongationError: {
        [VacancyToggleAction.Disable]: {
            title: 'error.vacancy.autoProlongation.disable.unknownException.title',
            content: 'error.vacancy.autoProlongation.disable.unknownException.content',
        },
        [VacancyToggleAction.Enable]: {
            title: 'error.vacancy.autoProlongation.enable.unknownException.title',
            content: 'error.vacancy.autoProlongation.enable.unknownException.content',
        },
    },
    VacancyAutoProlongationErrors: {
        content: 'error.vacancy.autoProlongation.batch.enable.unknownException.content',
    },
    VacancyDraftDeleteSuccess: {
        onlyOne: 'employer.vacancies.draftDelete.ok.title.onlyOne',
        multiple: 'employer.vacancies.draftDelete.ok.title.multiple',
    },
    VacancyDraftDeleteError: {
        error: 'error.vacancy.draftDelete.unknownException',
    },
    VacancyDraftMoveSuccess: {
        onlyOne: 'employer.vacancies.draftMove.ok.title.onlyOne',
        multiple: 'employer.vacancies.draftMove.ok.title.multiple',
    },
    NotifyEmptyResult: {
        empty: 'employer.vacanciesDashboard.ApplyFilterResultIsEmpty',
    },
    CancelScheduledPublicationSuccess: {
        text: 'employer.vacancies.cancelScheduled.ok',
    },
    ToggleVacancyOptionSuccess: {
        [VacancyToggleAction.Enable]: 'employer.vacancies.enableVacancyOption.ok',
        [VacancyToggleAction.Disable]: 'employer.vacancies.disableVacancyOption.ok',
    },
    ToggleVacancyOptionError: {
        [ToggleVacancyOptionErrorKey.FailedToDeleteExpired]:
            'employer.vacancies.toggleVacancyOption.FAILED_TO_DELETE_EXPIRED',
        [ToggleVacancyOptionErrorKey.PremiumAlreadySet]: 'employer.vacancies.toggleVacancyOption.PREMIUM_ALREDY_SET',
        [ToggleVacancyOptionErrorKey.PremiumNotSet]: 'employer.vacancies.toggleVacancyOption.PREMIUM_NOT_SET',
        [ToggleVacancyOptionErrorKey.FailedToDelete]: 'employer.vacancies.toggleVacancyOption.FAILED_TO_DELETE',
        [ToggleVacancyOptionErrorKey.NotZpVacancy]: 'employer.vacancies.toggleVacancyOption.NOT_ZP_VACANCY',
        [ToggleVacancyOptionErrorKey.FailedToSpendService]:
            'employer.vacancies.toggleVacancyOption.FAILED_TO_SPEND_SERVICE',
        [ToggleVacancyOptionErrorKey.FailedToReturnService]:
            'employer.vacancies.toggleVacancyOption.FAILED_TO_RETURN_SERVICE',
        [ToggleVacancyOptionErrorKey.FailedToGetServiceCount]:
            'employer.vacancies.toggleVacancyOption.FAILED_TO_GET_SERVICE_COUNT',
        [ToggleVacancyOptionErrorKey.NotEnoughQuotas]: 'employer.vacancies.toggleVacancyOption.NOT_ENOUGH_QUOTAS',
        [ToggleVacancyOptionErrorKey.VacancyIsNotActive]:
            'employer.vacancies.toggleVacancyOption.VACANCY_IS_NOT_ACTIVE',
        [ToggleVacancyOptionErrorKey.NotEnoughServices]: '',
    },
    ChangeVacancyVisibilitySuccess: {
        closed: 'employer.vacancy.visibility.success.hide',
        open: 'employer.vacancy.visibility.success.show',
    },
    PlannedPublicationSudoCancelError: {
        error: 'employer.vacancies.planned.publication.operation.not.allowed.sudo',
    },
};

const SuccessQaWrapper: FC<PropsWithChildren> = ({ children }) => (
    <div data-qa="vacancies-successMessage">{children}</div>
);

const ErrorQaWrapper: FC<PropsWithChildren> = ({ children }) => <div data-qa="vacancies-errorMessage">{children}</div>;

interface ArchiveVacancySuccessProps {
    name: string;
    count?: number;
    daysUntilReadOnly?: number;
}
const ArchiveVacancySuccess: TranslatedComponent<ArchiveVacancySuccessProps> = ({
    trls,
    name,
    count = 0,
    daysUntilReadOnly = 0,
}) => {
    const countedBegin = `${
        trls[TrlKeys.ArchiveVacancySuccess.anyBegin]
    }${NON_BREAKING_SPACE}${count}${NON_BREAKING_SPACE}`;
    const isDescriptionVisible = !!daysUntilReadOnly;
    const NotificationTitle = isDescriptionVisible ? NotificationHeading : Fragment;

    return (
        <SuccessQaWrapper>
            <NotificationTitle>
                <ConversionNumber
                    one={trls[TrlKeys.ArchiveVacancySuccess.single].replace('{0}', name)}
                    some={`${countedBegin}${trls[TrlKeys.ArchiveVacancySuccess.someEnd]}`}
                    many={`${countedBegin}${trls[TrlKeys.ArchiveVacancySuccess.manyEnd]}`}
                    value={count}
                    format={(trl) => format(trl, { '%s': count })}
                    hasValue={false}
                />
            </NotificationTitle>
            {isDescriptionVisible && (
                <p>
                    <ConversionNumber
                        one={trls[TrlKeys.ArchiveVacancySuccess.descriptionOne]}
                        some={trls[TrlKeys.ArchiveVacancySuccess.descriptionMany]}
                        many={trls[TrlKeys.ArchiveVacancySuccess.descriptionMany]}
                        value={daysUntilReadOnly}
                        format={(trl) => format(trl, { '{0}': daysUntilReadOnly })}
                        hasValue={false}
                    />
                </p>
            )}
        </SuccessQaWrapper>
    );
};
export const archiveVacancySuccess = {
    Element: translation(ArchiveVacancySuccess),
    kind: 'ok',
};

interface VacancyUpdateResultProps {
    count: number;
}
const VacancyUpdateSuccess: TranslatedComponent<VacancyUpdateResultProps & NotificationProps> = ({
    trls,
    count,
    ...props
}) => {
    const formatMultipleUpdate = useCallback((trl: string) => format(trl, { '{0}': count }), [count]);
    return (
        <Notification
            data-qa="vacancies-successMessage"
            addon={<CheckCircleFilledSize24 initialColor="positive" />}
            {...props}
        >
            <ConversionNumber
                hasValue={false}
                value={count}
                one={trls[TrlKeys.VacancyUpdateSuccess.successOne]}
                some={trls[TrlKeys.VacancyUpdateSuccess.successSome]}
                many={trls[TrlKeys.VacancyUpdateSuccess.successSome]}
                format={formatMultipleUpdate}
            />
        </Notification>
    );
};
export const vacancyUpdateSuccess = translation(VacancyUpdateSuccess);

const VacancyUpdateError: TranslatedComponent<VacancyUpdateResultProps & NotificationProps> = ({
    trls,
    count,
    ...props
}) => {
    const formatMultipleUpdate = useCallback((trl: string) => format(trl, { '{0}': count }), [count]);
    return (
        <Notification
            data-qa="vacancies-errorMessage"
            addon={<MinusCircleFilledSize24 initialColor="negative" data-qa="prolongate-snackbar-error" />}
            {...props}
        >
            <ConversionNumber
                hasValue={false}
                value={count}
                one={trls[TrlKeys.VacancyUpdateError.errorOne]}
                some={trls[TrlKeys.VacancyUpdateError.errorSome]}
                many={trls[TrlKeys.VacancyUpdateError.errorSome]}
                format={formatMultipleUpdate}
            />
        </Notification>
    );
};
export const vacancyUpdateError = translation(VacancyUpdateError);

const AutoProlongationPurchaseSuccess: TranslatedComponent = ({ trls }) => (
    <>{trls[TrlKeys.AutoProlongationPurchaseSuccess.message]}</>
);
export const autoProlongationPurchaseSuccess = {
    Element: translation(AutoProlongationPurchaseSuccess),
    kind: 'ok',
};
export const AUTO_PROLONGATION_PURCHASE_SUCCESS = 'AUTO_PROLONGATION_PURCHASE_SUCCESS';

const AutoProlongationPurchaseSuccesses: TranslatedComponent = ({ trls }) => (
    <>{trls[TrlKeys.AutoProlongationPurchaseSuccesses.message]}</>
);
export const autoProlongationPurchaseSuccesses = {
    Element: translation(AutoProlongationPurchaseSuccesses),
    kind: 'ok',
};
export const AUTO_PROLONGATION_PURCHASE_SUCCESSES = 'AUTO_PROLONGATION_PURCHASE_SUCCESSES';

interface VacancyAutoProlongationSuccessProps {
    name: string;
    action: VacancyToggleAction;
}
const VacancyAutoProlongationSuccess: TranslatedComponent<VacancyAutoProlongationSuccessProps> = ({
    trls,
    name,
    action,
}) => (
    <SuccessQaWrapper>
        <NotificationHeading>{trls[TrlKeys.VacancyAutoProlongationSuccess[action].title]}</NotificationHeading>
        {format(trls[TrlKeys.VacancyAutoProlongationSuccess[action].content], { '{0}': name })}
    </SuccessQaWrapper>
);
export const vacancyAutoProlongationSuccess = {
    Element: translation(VacancyAutoProlongationSuccess),
    kind: 'ok',
};
export const VACANCY_AUTO_PROLONGATION_SUCCESS = 'VACANCY_AUTO_PROLONGATION_SUCCESS';

const VacancyAutoProlongationSuccesses: TranslatedComponent = ({ trls }) => (
    <SuccessQaWrapper>
        <NotificationHeading>{trls[TrlKeys.VacancyAutoProlongationSuccesses.title]}</NotificationHeading>
        {trls[TrlKeys.VacancyAutoProlongationSuccesses.content]}
    </SuccessQaWrapper>
);
export const vacancyAutoProlongationSuccesses = {
    Element: translation(VacancyAutoProlongationSuccesses),
    kind: 'ok',
};
export const VACANCY_AUTO_PROLONGATION_SUCCESSES = 'VACANCY_AUTO_PROLONGATION_SUCCESSES';

interface VacancyAutoProlongationErrorProps {
    name: string;
    action: VacancyToggleAction;
}
const VacancyAutoProlongationError: TranslatedComponent<VacancyAutoProlongationErrorProps> = ({
    trls,
    name,
    action,
}) => (
    <ErrorQaWrapper>
        <NotificationHeading>
            {format(trls[TrlKeys.VacancyAutoProlongationError[action].title], { '{0}': name })}
        </NotificationHeading>
        {trls[TrlKeys.VacancyAutoProlongationError[action].content]}
    </ErrorQaWrapper>
);
export const vacancyAutoProlongationError = {
    Element: translation(VacancyAutoProlongationError),
    kind: 'error',
};
export const VACANCY_AUTO_PROLONGATION_ERROR = 'VACANCY_AUTO_PROLONGATION_ERROR';

const VacancyAutoProlongationErrors: TranslatedComponent = ({ trls }) => (
    <ErrorQaWrapper>{trls[TrlKeys.VacancyAutoProlongationErrors.content]}</ErrorQaWrapper>
);
export const vacancyAutoProlongationErrors = {
    Element: translation(VacancyAutoProlongationErrors),
    kind: 'error',
};
export const VACANCY_AUTO_PROLONGATION_ERRORS = 'VACANCY_AUTO_PROLONGATION_ERRORS';

interface VacancyDraftDeleteSuccessProps {
    draftsIds: number[];
}
const VacancyDraftDeleteSuccess: TranslatedComponent<VacancyDraftDeleteSuccessProps> = ({ trls, draftsIds }) => (
    <SuccessQaWrapper>
        {
            trls[
                draftsIds.length === 1
                    ? TrlKeys.VacancyDraftDeleteSuccess.onlyOne
                    : TrlKeys.VacancyDraftDeleteSuccess.multiple
            ]
        }
    </SuccessQaWrapper>
);
export const vacancyDraftDeleteSuccess = {
    Element: translation(VacancyDraftDeleteSuccess),
    kind: 'ok',
    autoClose: true,
};
export const VACANCY_DRAFT_DELETE_SUCCESS = 'VACANCY_DRAFT_DELETE_SUCCESS';

const VacancyDraftDeleteError: TranslatedComponent = ({ trls }) => (
    <ErrorQaWrapper>{trls[TrlKeys.VacancyDraftDeleteError.error]}</ErrorQaWrapper>
);
export const vacancyDraftDeleteError = {
    Element: translation(VacancyDraftDeleteError),
    kind: 'error',
};
export const VACANCY_DRAFT_DELETE_ERROR = 'VACANCY_DRAFT_DELETE_ERROR';

interface VacancyDraftMoveSuccessProps {
    draftsIds: number[];
}
const VacancyDraftMoveSuccess: TranslatedComponent<VacancyDraftMoveSuccessProps> = ({ trls, draftsIds }) => (
    <SuccessQaWrapper>
        {
            trls[
                draftsIds.length === 1
                    ? TrlKeys.VacancyDraftMoveSuccess.onlyOne
                    : TrlKeys.VacancyDraftMoveSuccess.multiple
            ]
        }
    </SuccessQaWrapper>
);
export const vacancyDraftMoveSuccess = {
    Element: translation(VacancyDraftMoveSuccess),
    kind: 'ok',
    autoClose: true,
};
export const VACANCY_DRAFT_MOVE_SUCCESS = 'VACANCY_DRAFT_MOVE_SUCCESS';

const NotifyEmptyResult: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.NotifyEmptyResult.empty]}</>;
export const emptyFiltersResult = {
    Element: translation(NotifyEmptyResult),
    autoClose: true,
};
export const EMPTY_FILTERS_RESULT = 'EMPTY_FILTERS_RESULT';

const CancelScheduledPublicationSuccess: TranslatedComponent = ({ trls }) => (
    <SuccessQaWrapper>{trls[TrlKeys.CancelScheduledPublicationSuccess.text]}</SuccessQaWrapper>
);
export const cancelScheduledPublicationSuccess = {
    Element: translation(CancelScheduledPublicationSuccess),
    kind: 'ok',
    autoClose: true,
};

interface ToggleVacancyOptionSuccessProps {
    vacancyName: string;
    action: VacancyToggleAction;
}
const ToggleVacancyOptionSuccess: TranslatedComponent<ToggleVacancyOptionSuccessProps> = ({
    trls,
    vacancyName,
    action,
}) => (
    <SuccessQaWrapper>
        {formatToReactComponent(trls[TrlKeys.ToggleVacancyOptionSuccess[action]], {
            '{0}': <span>{vacancyName}</span>,
        })}
    </SuccessQaWrapper>
);
export const toggleVacancyOptionSuccess = {
    Element: translation(ToggleVacancyOptionSuccess),
    kind: 'ok',
    autoClose: true,
};

interface ToggleVacancyOptionErrorProps {
    errorKey: ToggleVacancyOptionErrorKey;
}
const ToggleVacancyOptionError: TranslatedComponent<ToggleVacancyOptionErrorProps> = ({ trls, errorKey }) => (
    <ErrorQaWrapper>{trls[TrlKeys.ToggleVacancyOptionError[errorKey]]}</ErrorQaWrapper>
);
export const toggleVacancyOptionError = {
    Element: translation(ToggleVacancyOptionError),
    kind: 'error',
};

interface ChangeVacancyVisibilitySuccessProps {
    type: 'open' | 'closed';
}
const ChangeVacancyVisibilitySuccess: TranslatedComponent<ChangeVacancyVisibilitySuccessProps> = ({ trls, type }) => (
    <SuccessQaWrapper>{trls[TrlKeys.ChangeVacancyVisibilitySuccess[type]]}</SuccessQaWrapper>
);
export const changeVacancyVisibilitySuccess = {
    Element: translation(ChangeVacancyVisibilitySuccess),
    kind: 'ok',
    autoClose: true,
};

const PlannedPublicationSudoCancelError: TranslatedComponent = ({ trls }) => (
    <ErrorQaWrapper>{trls[TrlKeys.PlannedPublicationSudoCancelError.error]}</ErrorQaWrapper>
);
export const plannedPublicationSudoCancelError = {
    Element: translation(PlannedPublicationSudoCancelError),
    kind: 'error',
    autoClose: true,
};
