import { FC, Fragment, useEffect } from 'react';
import { ValueOf } from 'type-fest';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ErrorIds, SuccessIds, VacancyMessageTypes } from 'src/models/vacancyMessages';

import archiveVacancyApplicantsFeedbackSuccess from 'src/components/Notifications/ArchiveVacancyApplicantsFeedbackSuccess';
import {
    archiveVacancySuccess,
    toggleVacancyOptionSuccess,
    vacancyAutoProlongationError,
    vacancyAutoProlongationSuccess,
    VacancyToggleAction,
    vacancyUpdateSuccess,
} from 'src/components/Notifications/EmployerVacancies';
import { useNotification } from 'src/components/Notifications/Provider';

const TrlKeys = {
    VacancyErrorMessage: {
        [ErrorIds.F]: 'error.employer.vacancyAlreadyArchived',
        [ErrorIds.D]: 'error.vacancy.archive.accessDenied',
        [ErrorIds.EE1]: 'error.vacancy.prolongate.accessDenied',
        [ErrorIds.EE2]: 'error.vacancy.prolongate.quotaExceeded',
        [ErrorIds.EE3]: 'error.vacancy.prolongate.minimumProlongatePeriod',
        [ErrorIds.EE4]: 'error.vacancy.prolongate.notEnoughPremiumVacancy',
        [ErrorIds.EE5]: 'error.vacancy.prolongate.notEnoughAnonymousVacancy',
        [ErrorIds.EE6]: 'error.vacancy.prolongate.notEnoughAdvertisingVacancy',
        [ErrorIds.EE7]: 'error.vacancy.prolongate.notEnoughVacancyPost',
        [ErrorIds.EE8]: 'error.vacancy.prolongate.archivedVacancy',
        [ErrorIds.EE9]: 'error.vacancy.prolongate.notEnoughFpn',
        [ErrorIds.EE11]: 'error.vacancy.prolongate.unknownException',
        [ErrorIds.EE14]: 'error.vacancy.prolongate.notEnoughRenewalVacancy',
        [ErrorIds.EE15]: 'error.vacancy.prolongate.notEnoughRegionalRenewalVacancy',
        [ErrorIds.EE18]: 'error.vacancy.prolongate.notEnoughRegionalPremiumVacancy',
        [ErrorIds.EE19]: 'error.vacancy.prolongate.notEnoughRegionalVacancy',
        [ErrorIds.EE21]: 'error.vacancy.prolongate.notEnoughRegionalFPN',
        [ErrorIds.EE22]: 'error.standardPlus.tooEarlyProlongation',
        [ErrorIds.EE23]: 'error.standardPlus.closedProlongation',
        [ErrorIds.EE24]: 'error.free.noRightsProlongation',
        [ErrorIds.EE25]: 'error.vacancy.prolongate.vacancy.haveLeafRegion',
        [ErrorIds.EE26]: 'error.vacancy.prolongate.area.postDisabled',
        [ErrorIds.EE27]: 'error.vacancy.autoProlongation.enable.unknownException.content',
        [ErrorIds.EE28]: 'error.vacancy.autoProlongation.disable.unknownException.content',
        [ErrorIds.EE29]: 'error.vacancy.renewal.tooEarly',
        [ErrorIds.ERR]: 'error.vacancy.nullSelection',
        [ErrorIds.NA]: 'employer.vacancy.post.error.notAvailableVacancyArchiveNotifier',
        [ErrorIds.EVU]: 'employer.vacancy.upgrade.error',
        [SuccessIds.EA]: 'employer.vacancy.extends.result',
    },
    VacancyScheduled: {
        scheduled: 'employer.vacancy.create.scheduledNotification',
    },
    VacancyPublished: {
        published: 'vacancy.statInfo.publishedandsearchable',
        closed: 'vacancy.statInfo.publish.closed',
        untrusted: 'vacancy.statInfo.untrusted',
        closedUntrusted: 'vacancy.statInfo.publish.closed.untrusted',
        waiting: 'employer.vacancy.premoderate.status.wait',
        needFix: 'employer.vacancy.premoderate.status.need_fix',
    },
    VacancyRestoredToArchive: {
        restored: 'employer.vacancyesArchive.backToArchive.message.firstPart',
        publish: 'employer.vacancyesArchive.backToArchive.message.secondPart',
    },
    VacancyBlacklistVacancyLimitError: {
        limit: 'pageControls.Blacklist.Vacancy.limit',
        clear: 'pageControls.Blacklist.clear',
    },
    VacancyBlacklistEmployerLimitError: {
        limit: 'pageControls.Blacklist.Employer.limit',
        clear: 'pageControls.Blacklist.clear',
    },
};

interface VacancyPublishedProps {
    approved?: boolean;
    trusted: boolean;
    status: { waiting?: boolean; needFix?: boolean };
    isClosed: boolean;
}

const VacancyPublished: TranslatedComponent<VacancyPublishedProps> = ({
    approved,
    trusted,
    status,
    isClosed,
    trls,
}) => {
    if (status.waiting) {
        return <>{trls[TrlKeys.VacancyPublished.waiting]}</>;
    }
    if (status.needFix) {
        return <>{trls[TrlKeys.VacancyPublished.needFix]}</>;
    }
    if (!approved && !trusted && isClosed) {
        return <>{trls[TrlKeys.VacancyPublished.closedUntrusted]}</>;
    }
    if (!approved && !trusted) {
        return <>{trls[TrlKeys.VacancyPublished.untrusted]}</>;
    }
    if (isClosed) {
        return <>{trls[TrlKeys.VacancyPublished.closed]}</>;
    }
    return <>{trls[TrlKeys.VacancyPublished.published]}</>;
};

export const vacancyPublished = {
    Element: translation(VacancyPublished),
    kind: 'ok',
};

interface VacancyRestoredToArchiveProps {
    vacancyId: number;
}
const VacancyRestoredToArchive: TranslatedComponent<VacancyRestoredToArchiveProps> = ({ trls, vacancyId }) => {
    return (
        <Fragment>
            {trls[TrlKeys.VacancyRestoredToArchive.restored]}&nbsp;
            <BlokoLink Element={SPALink} to={`/employer/vacancy/restore/${vacancyId}`}>
                {trls[TrlKeys.VacancyRestoredToArchive.publish]}
            </BlokoLink>
        </Fragment>
    );
};
export const vacancyRestoredToArchive = {
    Element: translation(VacancyRestoredToArchive),
    kind: 'ok',
};

const VacancyBlacklistVacancyLimitError: TranslatedComponent = ({ trls }) => {
    return (
        <>
            {trls[TrlKeys.VacancyBlacklistVacancyLimitError.limit]}&nbsp;
            <BlokoLink Element={SPALink} to="/applicant/blacklist/vacancy" target="_blank">
                {trls[TrlKeys.VacancyBlacklistVacancyLimitError.clear]}
            </BlokoLink>
        </>
    );
};
export const vacancyBlacklistVacancyLimitError = {
    Element: translation(VacancyBlacklistVacancyLimitError),
    kind: 'error',
    autoClose: true,
};

const VacancyBlacklistEmployerLimitError: TranslatedComponent = ({ trls }) => {
    return (
        <>
            {trls[TrlKeys.VacancyBlacklistEmployerLimitError.limit]}&nbsp;
            <BlokoLink Element={SPALink} to="/applicant/blacklist/employer" target="_blank">
                {trls[TrlKeys.VacancyBlacklistEmployerLimitError.clear]}
            </BlokoLink>
        </>
    );
};
export const vacancyBlacklistEmployerLimitError = {
    Element: translation(VacancyBlacklistEmployerLimitError),
    kind: 'error',
    autoClose: true,
};

interface VacancyErrorMessageProps {
    id: ValueOf<typeof ErrorIds>;
    name: string;
}
const VacancyErrorMessage: TranslatedComponent<VacancyErrorMessageProps> = ({ id, name, trls }) => {
    return <>{format(trls[TrlKeys.VacancyErrorMessage[id]], { '{0}': name })}</>;
};

export const vacancyErrorMessage = {
    Element: translation(VacancyErrorMessage),
    type: 'error',
};

const VacancyScheduled: TranslatedComponent = ({ trls }) => {
    return <>{trls[TrlKeys.VacancyScheduled.scheduled]}</>;
};
export const vacancyScheduled = {
    Element: translation(VacancyScheduled),
    kind: 'ok',
    autoClose: true,
};

const VacancyNotifications: FC = () => {
    const isNewVacancy = useSelector((state) => state.isNewVacancy);
    const location = useSelector((state) => state.router.location);
    const vacancyMessages = useSelector((state) => state.vacancyMessages);
    const vacancyView = useSelector((state) => state.vacancyView);
    const { addNotification } = useNotification();

    useEffect(() => {
        // ignore change a tab in location by state on vacancy page
        if (location.state?.hasOwnProperty('tab')) {
            return;
        }

        const { name } = vacancyView;

        vacancyMessages.forEach(({ id, type }) => {
            if (id === SuccessIds.A) {
                addNotification(archiveVacancySuccess, {
                    props: {
                        count: 1,
                        name,
                        daysUntilReadOnly: parseInt(location.query.adays, 10),
                    },
                });
                if (location.query.feedbackSent) {
                    addNotification(archiveVacancyApplicantsFeedbackSuccess);
                }
            } else if (id === SuccessIds.E) {
                addNotification(vacancyUpdateSuccess, { props: { count: 1 } });
            } else if (id === SuccessIds.APE) {
                addNotification(vacancyAutoProlongationSuccess, {
                    props: { name, action: VacancyToggleAction.Enable },
                });
            } else if (id === SuccessIds.APC) {
                addNotification(vacancyAutoProlongationSuccess, {
                    props: { name, action: VacancyToggleAction.Disable },
                });
            } else if (id === ErrorIds.EE27) {
                addNotification(vacancyAutoProlongationError, { props: { name, action: VacancyToggleAction.Enable } });
            } else if (id === ErrorIds.EE28) {
                addNotification(vacancyAutoProlongationError, { props: { name, action: VacancyToggleAction.Disable } });
            } else if (id === SuccessIds.VOE) {
                addNotification(toggleVacancyOptionSuccess, {
                    props: { vacancyName: name, action: VacancyToggleAction.Enable },
                });
            } else if (id === SuccessIds.VOD) {
                addNotification(toggleVacancyOptionSuccess, {
                    props: { vacancyName: name, action: VacancyToggleAction.Disable },
                });
            } else if (type === VacancyMessageTypes.Error) {
                addNotification(vacancyErrorMessage, { props: { id, name } });
            }
        });

        if (isNewVacancy) {
            addNotification(vacancyPublished, {
                uniqueType: true,
                props: {
                    approved: vacancyView.approved,
                    trusted: vacancyView.company['@trusted'],
                    status: vacancyView.status,
                    isClosed: vacancyView.closedForApplicants,
                },
            });
        }

        if (location.query.makeVacancyVisible === 'true') {
            addNotification(vacancyRestoredToArchive, { props: { vacancyId: vacancyView.vacancyId! } });
        }

        if (location.query.successScheduled === 'true') {
            addNotification(vacancyScheduled);
        }
    }, [addNotification, isNewVacancy, location, vacancyMessages, vacancyView]);

    return null;
};

export default VacancyNotifications;
