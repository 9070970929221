import { useRef } from 'react';

import { TooltipHover, Text } from '@hh.ru/magritte-ui';
import { VerifiedBadgeFilledSize16, VerifiedBadgeFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useTopLevelSite } from 'src/hooks/useSites';
import { TopLevelSite } from 'src/models/topLevelSite';

import styles from './trusted-employer-check.less';

const TrlKeys = {
    trustedEmployer: 'tooltip.checked.employer',
};

export enum TrustedEmployerCheckSize {
    Small = 'small',
    Large = 'large',
}

interface TrustedEmployerCheckProps {
    size: TrustedEmployerCheckSize;
    isMagritte?: boolean;
    onClick?: VoidFunction;
    tooltipPlacement?: 'top-center' | 'bottom-center';
    tooltipSize?: 'small' | 'medium';
    onTooltipAppear?: VoidFunction;
}

const getCheckedEmployerFaqLink = (site: TopLevelSite): string => {
    switch (site) {
        case TopLevelSite.UA:
            return '/article/20099';
        case TopLevelSite.BY:
            return '/article/19873';
        case TopLevelSite.ZP:
            return '/article/20099';
        default:
            return 'https://feedback.hh.ru/article/details/id/5951';
    }
};

const TrustedEmployerCheck: TranslatedComponent<TrustedEmployerCheckProps> = ({
    trls,
    size,
    isMagritte,
    onClick,
    tooltipPlacement = 'top-center',
    tooltipSize = 'medium',
    onTooltipAppear,
}) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const topLevelSite = useTopLevelSite();

    const icon = (
        <span className={styles.trustedEmployerCheckIcon} ref={activatorRef}>
            {size === TrustedEmployerCheckSize.Small && <VerifiedBadgeFilledSize16 initialColor="accent" />}
            {size === TrustedEmployerCheckSize.Large && <VerifiedBadgeFilledSize24 initialColor="accent" />}
        </span>
    );

    return isMagritte ? (
        <SPALink target="_blank" to={getCheckedEmployerFaqLink(topLevelSite)} data-qa="trusted-employer-link">
            <>
                {icon}
                <TooltipHover
                    placement={tooltipPlacement}
                    size={tooltipSize}
                    activatorRef={activatorRef}
                    onAppear={onTooltipAppear}
                >
                    <Text>{trls[TrlKeys.trustedEmployer]}</Text>
                </TooltipHover>
            </>
        </SPALink>
    ) : (
        <BlokoLink
            Element={SPALink}
            target="_blank"
            to={getCheckedEmployerFaqLink(topLevelSite)}
            data-qa="trusted-employer-link"
            onClick={onClick}
        >
            <HoverTip
                render={() => trls[TrlKeys.trustedEmployer]}
                placement={TipPlacement.Top}
                layer={TipLayer.Overlay}
                host={!process.env.SSR ? document.body : null}
                activatorRef={activatorRef}
            >
                {icon}
            </HoverTip>
        </BlokoLink>
    );
};

export default translation(TrustedEmployerCheck);
