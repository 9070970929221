import { useState, SyntheticEvent } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import showPhoneBtnClick from '@hh.ru/analytics-js-events/build/xhh/applicant/contacts/contacts_show_phone_button_click';
import { Text, Banner, Card, Cell, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { PhoneFilledSize24, EnvelopeFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { ContactInfo } from 'src/models/vacancyView.types';
import { ELLIPSIS } from 'src/utils/constants/symbols';

import useContactsShown from 'src/components/VacancyView/VacancyContactsRedesignedV2/hooks/useContactsShown';

interface Props {
    contactInfo?: ContactInfo | null;
    onPhoneClick: (numberType: string) => void;
    onEmailClick?: () => void;
    isNameHidden?: boolean;
    vacancyId: number;
    employerId?: number;
    isChatEnabled: boolean;
}

const TrlKeys = {
    email: 'vacancy.contacts.email',
    phoneMain: 'vacancy.contacts.phone.main',
    phoneAdditional: 'vacancy.contacts.phone.additional',
    bannerText: 'vacancy.contacts.callTracking.disclaimer.text.updated',
    showPhone: 'vacancy.contacts.phone.actions.show',
};

/*
 * Это вторая версия редизайна контактов по вакансии
 * Постепенно заменит остальные версии в случае успешного эксперимента
 * */
const VacancyContactsRedesignedV2: TranslatedComponent<Props> = ({
    contactInfo,
    onPhoneClick,
    onEmailClick,
    trls,
    isNameHidden,
    vacancyId,
    employerId,
    isChatEnabled,
}) => {
    const { fio, email, callTrackingEnabled } = contactInfo || {};
    // пока храним состояния открытия телефона по индексу - в будущем API перейдем на id
    const [availablePhonesByIndex, setAvailablePhonesByIndex] = useState<{ [key: number]: boolean }>({});
    const phones = contactInfo?.phones?.phones;
    const commonCardProps = {
        paddingRight: 24,
        padding: 16,
        borderWidth: 'default',
        borderRadius: 24,
        stretched: true,
        hoverEnabled: true,
    } as const;
    const isShowContactsExpEnabled = useExperiment('web_redesign_vacancy_contacts_c');
    const contactsShownFn = useContactsShown({
        employerId,
        vacancyId,
        chat: isChatEnabled,
        callTrackingBanner: !!(phones?.length && callTrackingEnabled),
        mainNumber: !!(phones && phones.length > 0),
        addNumber: !!(phones && phones.length > 1),
        email: !!email,
    });

    return (
        <ElementShownAnchor fn={contactsShownFn}>
            <VSpacingContainer default={12}>
                {fio && !isNameHidden && <Text typography="title-5-semibold">{fio}</Text>}
                {Boolean(phones?.length) && callTrackingEnabled && (
                    <Banner style="secondary" showClose={false} content={<Text>{trls[TrlKeys.bannerText]}</Text>} />
                )}
                {phones?.map((phone, index) => {
                    const { country, city, number, comment } = phone;
                    const formattedPhone = `+${country}\u00a0${city}\u00a0${number}`;
                    const isPhoneVisible = availablePhonesByIndex[index] || !isShowContactsExpEnabled;
                    const onPhoneCardClick = (e: SyntheticEvent) => {
                        const numberType = index === 0 ? 'main' : 'additional';
                        if (isPhoneVisible) {
                            onPhoneClick(numberType);
                        } else {
                            e.preventDefault();
                            setAvailablePhonesByIndex((prevState) => ({ ...prevState, [index]: true }));
                            showPhoneBtnClick({
                                vacancyId: String(vacancyId),
                                numberType,
                            });
                        }
                    };
                    return (
                        <SPALink key={formattedPhone} to={`tel:+${country}${city}${number}`} onClick={onPhoneCardClick}>
                            <Card {...commonCardProps} data-qa="vacancy-contacts-drop__phone-card">
                                <Cell right={<PhoneFilledSize24 />} align="center">
                                    <Text typography="label-3-regular" style="secondary">
                                        {index === 0 ? trls[TrlKeys.phoneMain] : trls[TrlKeys.phoneAdditional]}
                                    </Text>
                                    <VSpacing default={4} />
                                    <Text typography="label-2-regular" data-qa="vacancy-contacts-drop__phone">
                                        {isPhoneVisible ? (
                                            <Text Element="span">{formattedPhone}</Text>
                                        ) : (
                                            <>
                                                +{country}
                                                {ELLIPSIS}
                                                <Text Element="span" style="accent">
                                                    {trls[TrlKeys.showPhone]}
                                                </Text>
                                            </>
                                        )}
                                    </Text>
                                </Cell>
                                {Boolean(comment) && (
                                    <>
                                        <VSpacing default={6} />
                                        <Text typography="label-3-regular" style="secondary">
                                            {comment}
                                        </Text>
                                    </>
                                )}
                            </Card>
                        </SPALink>
                    );
                })}
                {email && (
                    <SPALink to={`mailto:${email}`} onClick={onEmailClick}>
                        <Card {...commonCardProps} data-qa="vacancy-contacts-drop__email-card">
                            <Cell right={<EnvelopeFilledSize24 />} align="center">
                                <Text typography="label-3-regular" style="secondary">
                                    {trls[TrlKeys.email]}
                                </Text>
                                <VSpacing default={4} />
                                <Text typography="label-2-regular" data-qa="vacancy-contacts-drop__email">
                                    {email}
                                </Text>
                            </Cell>
                        </Card>
                    </SPALink>
                )}
            </VSpacingContainer>
        </ElementShownAnchor>
    );
};

export default translation(VacancyContactsRedesignedV2);
