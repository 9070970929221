import { useSearchSettings } from 'src/hooks/search/useSearchSettings';

import styles from 'src/components/VacancySearchItem/styles.less';

interface ReturnType {
    wideCardContainerClassName: string;
    narrowCardContainerClassName?: string;
    separateLineOnNarrowCardClassName?: string;
}

// Есть 2 вида карточки вакансии - узкая и широкая карточка
// Узкая используется на xs и s, и на странице карты вакансий (независимо от разрешения экрана)
// Внутри компонентов карточки используются классы-контейнеры из этого хука
// В них оборачиваются элементы, специфичные для широкой/узкой карточки
export const useCardContainerClassNames = (): ReturnType => {
    const { isVacancySearchMapPage } = useSearchSettings();

    if (isVacancySearchMapPage) {
        return {
            wideCardContainerClassName: styles.hidden,
            narrowCardContainerClassName: undefined,
            separateLineOnNarrowCardClassName: styles.separateLine,
        };
    }
    return {
        wideCardContainerClassName: styles.wideContainer,
        narrowCardContainerClassName: styles.narrowContainer,
        separateLineOnNarrowCardClassName: styles.separateLineOnS,
    };
};
