const getHeight = (element: HTMLElement, hiddenClass: string, paddingTop?: number, paddingBottom?: number) => {
    element.classList.remove(hiddenClass);
    element.style.height = 'auto';

    if (paddingTop) {
        element.style.paddingTop = `${paddingTop}px`;
    }

    if (paddingBottom) {
        element.style.paddingBottom = `${paddingBottom}px`;
    }

    const height = element.clientHeight;
    return `${height}px`;
};

interface VerticalFadeInProps {
    hiddenClass: string;
    duration?: `${number}s`;
    propertyList?: { height?: number; paddingTop?: number; paddingBottom?: number; opacity?: number };
    abortSignal?: AbortSignal;
}

const createAbortableRequestAnimationFrame = (signal?: AbortSignal) => (callback: () => void) => {
    if (signal?.aborted) {
        return undefined;
    }

    const requestId = requestAnimationFrame(callback);

    function onAbort() {
        cancelAnimationFrame(requestId);
        signal?.removeEventListener('abort', onAbort);
    }

    signal?.addEventListener('abort', onAbort);

    return requestId;
};

export default (
    element: HTMLElement,
    {
        hiddenClass,
        duration = '1s',
        propertyList = { height: undefined, paddingTop: undefined, paddingBottom: undefined, opacity: 1 },
        abortSignal,
    }: VerticalFadeInProps
): Promise<void> => {
    return new Promise((resolve) => {
        const height = getHeight(element, hiddenClass, propertyList.paddingTop, propertyList.paddingBottom);
        let state = false;
        const abortableRequestAnimationFrame = createAbortableRequestAnimationFrame(abortSignal);

        for (const transiton in propertyList) {
            element.style[transiton as never] = 0 as never;
        }

        const transitionEventEnd = (event: TransitionEvent) => {
            if (event.target !== element || event.propertyName !== 'height') {
                return;
            }
            abortableRequestAnimationFrame(() => {
                if (state) {
                    return;
                }

                element.style.height = 'auto';
                element.dataset.qa = 'fade-in-transition-end';
                state = true;
                resolve();
                element.removeEventListener('transitionend', transitionEventEnd);
            });
        };

        abortableRequestAnimationFrame(() => {
            element.style.transition = `height ${duration}, padding ${duration}, opacity ${duration}`;
            abortableRequestAnimationFrame(() => {
                element.style.height = height;

                if (propertyList.opacity) {
                    element.style.opacity = `${propertyList.opacity}`;
                }
                if (propertyList.paddingTop) {
                    element.style.paddingTop = `${propertyList.paddingTop}px`;
                }
                if (propertyList.paddingBottom) {
                    element.style.paddingBottom = `${propertyList.paddingBottom}px`;
                }
            });
        });

        element.addEventListener('transitionend', transitionEventEnd);

        abortSignal?.addEventListener('abort', () => {
            element.removeEventListener('transitionend', transitionEventEnd);
        });
    });
};
