import { useLayoutEffect, FC, useRef } from 'react';

import verticalFadeIn from 'Modules/verticalFadeIn';
import ApplicantActivityCard from 'src/components/Applicant/ActivityCard';

import styles from './style.less';

const ActivityCardAnimated: FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (containerRef.current) {
            const abortController = new AbortController();

            void verticalFadeIn(containerRef.current, {
                hiddenClass: styles.containerHidden,
                propertyList: {
                    height: undefined,
                    paddingTop: 0,
                    paddingBottom: 0,
                    opacity: 1,
                },
                abortSignal: abortController.signal,
            });

            return () => {
                abortController.abort();
            };
        }

        return undefined;
    }, []);

    return (
        <div ref={containerRef} className={styles.containerHidden}>
            <ApplicantActivityCard variant="response" showScoreChange />
        </div>
    );
};

export default ActivityCardAnimated;
