export enum ResumeAccessType {
    Everyone = 'everyone',
    Clients = 'clients',
    WhiteList = 'whitelist',
    BlackList = 'blacklist',
    Direct = 'direct',
    NoOne = 'no_one',
}

export const EVERYONE = ResumeAccessType.Everyone;
export const CLIENTS = ResumeAccessType.Clients;
export const WHITELIST = ResumeAccessType.WhiteList;
export const BLACKLIST = ResumeAccessType.BlackList;
export const DIRECT = ResumeAccessType.Direct;
export const NO_ONE = ResumeAccessType.NoOne;

const RESUME_ACCESS_TYPES = [EVERYONE, CLIENTS, WHITELIST, BLACKLIST, DIRECT, NO_ONE];
export default RESUME_ACCESS_TYPES;
