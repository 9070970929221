import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import addVacancyFavoritesExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_communication/add_vacancy_favorites';
import { usePush } from '@hh.ru/redux-spa-middleware';

import { makeToggleFavorite, makeToggleFavoriteAnonymous } from 'Modules/VacancyToFavorite';
import { useNotification } from 'src/components/Notifications/Provider';
import favoriteError, { VacancyFavoriteErrorCode } from 'src/components/Notifications/VacancyFavoriteError';
import favoriteSuccess from 'src/components/Notifications/VacancyFavoriteSuccess';
import { addUserLabelsForVacancies, removeUserLabelsForVacancies } from 'src/models/userLabelsForVacancies/userLabels';
import { UserType } from 'src/models/userType';
import UserLabel from 'src/utils/constants/userLabels';
import { isServerError } from 'src/utils/fetcher';
import { UXFeedback } from 'src/utils/uxfeedback';

import { useSelector } from 'src/hooks/useSelector';

interface UseFavoriteApplicantProps {
    vacancyId?: number;
    employerId?: number;
    onFavoriteStateChanged?: (inFavorites: boolean) => void;
}

const useFavoriteApplicant = ({
    vacancyId,
    employerId,
    onFavoriteStateChanged,
}: UseFavoriteApplicantProps): [boolean | null, (cleanup?: boolean) => Promise<void>] => {
    const { addNotification } = useNotification();
    const userLabels = useSelector(({ userLabelsForVacancies }) => userLabelsForVacancies);
    const toggleFavorite = useRef(makeToggleFavorite());
    const dispatch = useDispatch();

    const isFavoriteVacancy =
        vacancyId && vacancyId in userLabels ? userLabels[vacancyId].includes(UserLabel.Favorite) : null;

    const toggle = useCallback(
        async (cleanup?: boolean) => {
            let isFavoriteVacancyState;
            if (!vacancyId) {
                return;
            }

            try {
                isFavoriteVacancyState = await toggleFavorite.current({
                    isFavoriteVacancy,
                    vacancyId,
                    employerId,
                    cleanup,
                });
            } catch (error) {
                if (isServerError<{ errorCode: string }>(error)) {
                    addNotification(favoriteError, {
                        props: {
                            code: error.response?.data?.errorCode as VacancyFavoriteErrorCode,
                            toggle,
                        },
                    });
                }
                return;
            }

            if (isFavoriteVacancyState) {
                dispatch(addUserLabelsForVacancies({ vacancyId, labels: [UserLabel.Favorite] }));
                addNotification(favoriteSuccess);
                addVacancyFavoritesExternal({
                    category: 'applicant',
                    params: {
                        product_id: vacancyId,
                    },
                });
            } else {
                dispatch(removeUserLabelsForVacancies({ vacancyId, label: UserLabel.Favorite }));
            }
            onFavoriteStateChanged?.(!!isFavoriteVacancyState);
        },
        [addNotification, dispatch, employerId, isFavoriteVacancy, onFavoriteStateChanged, vacancyId]
    );

    return [isFavoriteVacancy, toggle];
};

interface UseFavoriteAnonymousProps {
    vacancyId?: number;
    employerId?: number;
}

const useFavoriteAnonymous = ({ vacancyId, employerId }: UseFavoriteAnonymousProps) => {
    const toggleFavorite = useRef(makeToggleFavoriteAnonymous());
    const { addNotification } = useNotification();
    const push = usePush();

    const toggle = useCallback(async () => {
        if (!vacancyId) {
            return;
        }
        let redirectUrl;
        try {
            redirectUrl = await toggleFavorite.current({ employerId, vacancyId });
        } catch (_) {
            addNotification(favoriteError, {
                props: {
                    toggle,
                },
            });
            return;
        }

        if (redirectUrl) {
            push(redirectUrl);
            addVacancyFavoritesExternal({
                category: 'anonymous',
                params: {
                    product_id: vacancyId,
                },
            });
        }
    }, [addNotification, employerId, push, vacancyId]);

    return toggle;
};

interface UseFavoriteResult {
    isFavoriteVacancy: boolean | null;
    toggleIsFavoriteVacancy: () => Promise<void>;
    isAvailableChangeFavoriteState: boolean;
}

interface UseFavoriteProps extends UseFavoriteAnonymousProps, UseFavoriteApplicantProps {
    isClosedVacancy: boolean;
    isSendUxFeedback?: boolean;
}

export default (initialValues: UseFavoriteProps): UseFavoriteResult => {
    const [isFavoriteVacancy, toggleFavoriteApplicant] = useFavoriteApplicant(initialValues);
    const toggleFavoriteAnonymous = useFavoriteAnonymous(initialValues);
    const userType = useSelector(({ userType }) => userType);

    return {
        isFavoriteVacancy,
        toggleIsFavoriteVacancy: () => {
            if (initialValues.isSendUxFeedback && !isFavoriteVacancy) {
                UXFeedback.sendEvent('add_vacancy_to_favorite_button_clicked');
            }
            return userType === UserType.Applicant ? toggleFavoriteApplicant() : toggleFavoriteAnonymous();
        },
        isAvailableChangeFavoriteState:
            (userType === UserType.Applicant || userType === UserType.Anonymous) && !initialValues.isClosedVacancy,
    };
};
